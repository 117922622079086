.os {
  font-family: "Open Sans", sans-serif;
}
/* Cursor Pointer */
.cu {
  cursor: pointer;
}
.bd-5 {
  border-radius: 5px;
}
/* Font Size */
.px12 {
  font-size: 12px;
}
.px13 {
  font-size: 13px;
}
.px14 {
  font-size: 14px;
}
.px15 {
  font-size: 15px;
}
.px16 {
  font-size: 16px;
}
.px20 {
  font-size: 20px;
}
.px24 {
  font-size: 24px;
}
.px40 {
  font-size: 40px;
}
.px48 {
  font-size: 48px;
}
/* Font Weight */
.f4 {
  font-weight: 400;
}
.f6 {
  font-weight: 600;
}
.f7 {
  font-weight: 700;
}
/* Color */
.cl-b {
  color: rgba(0, 0, 0, 0.75);
}
.cl-g {
  color: rgba(40, 131, 26, 1);
}
.cl-w {
  color: rgba(255, 255, 255, 1);
}
.cl-bl {
  color: rgba(9, 16, 152, 1);
}
/* Background Color */
.bc-e5 {
  background-color: #e5e5e5;
}
.bc-23 {
  background-color: rgba(237, 238, 252, 1);
}
.bc-d7 {
  background-color: #d7d7f4;
}
.bc-24 {
  background-color: rgba(245, 246, 252, 1);
}
/* Padding Top */
.pt6 {
  padding-top: 6px;
}
/* Home Page */
.log-box {
  height: 40px;
  width: 104px;
  border-radius: 8px;
  border: 1px solid rgba(40, 131, 26, 1);
  color: rgba(40, 131, 26, 1);
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
}
.sign-box {
  height: 40px;
  width: 104px;
  border-radius: 8px;
  background-color: rgba(40, 131, 26, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
}
.bb {
  border-bottom: 2px solid rgba(121, 133, 240, 0.15);
  /* box-shadow: 2px 2px rgba(121, 133, 240, 0.15); */
}
.start-btn {
  height: 59px;
  width: 154px;
  background-color: rgba(40, 131, 26, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding-top: 14px;
  cursor: pointer;
  border-radius: 8px;
}

.v4 {
  position: absolute;
  right: 0px;
  bottom: -75px;
}

.star {
  position: absolute;
  top: 77%;
  left: 47%;
}
/* Footer */
.up-btn {
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: rgba(40, 131, 26, 1);
}
.v51 {
  position: relative;
  margin-left: 12px;
  margin-top: 2px;
}
.v52 {
  position: relative;
  margin-top: -29px;
  margin-left: 12px;
}
.v53 {
  position: relative;
  margin-left: 12px;
  margin-top: -14px;
}
.up-text {
  transform: rotateZ(-91deg);
  margin-top: -14px;
  margin-left: -68px;
}
/* About Page */
.about-v4 {
  position: absolute;
  right: 0px;
  top: 50%;
}
/* Service Pages */
.servic-box {
  height: 225px;
  width: 300px;
  border-radius: 8px;
  border: 1px solid rgba(106, 106, 124, 0.1);
  margin-left: auto;
  margin-right: auto;
}
.vector4 {
  overflow: hidden !important;
  background-image: url("./Images/vector4.png");
  background-position: right top;
  background-repeat: no-repeat;
}
/* Contact Page */
.contact-br {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
}
.sub-btn {
  height: 52px;
  width: 115px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 400;
  background-color: rgba(40, 131, 26, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  /* padding-top: 5px; */
  cursor: pointer;
}
/* Login Page */
.img-bc {
  /* overflow: hidden !important; */
  background-image: url("./Images/backgroun_image.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.mobile {
  display: none;
}
@media (max-width: 550px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .start-btn {
    height: 40px;
    width: 104px;
    padding-top: 10px;
  }
  .px48 {
    font-size: 24px;
  }
  .px20 {
    font-size: 14px;
  }
  .star {
    top: 91%;
    left: -4%;
  }
}

.v4-mobile {
  overflow: hidden !important;
  background-image: url("./Images/vector4.png");
  background-position: right bottom;
  background-repeat: no-repeat;
}
.act-link::after {
  content: "";
  width: 20px;
  height: 2px;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 160, 1);
  left: 23px;
}
.position-relative:hover::after {
  content: "";
  width: 20px;
  height: 2px;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 160, 1);
  left: 23px;
}
.pd-26 {
  padding: 16px;
}
/* .back-to-top {
  visibility: visible !important;
} */
.folder-box {
  border: 1px dashed;
}

/* upload hide */
#input1[type="file"] {
  display: none;
}
.form-control:read-only {
  background-color: rgba(245, 246, 252, 1) !important;
}
@media (max-width: 1287px) {
  .pd-26 {
    padding: 10px;
  }
}
#hide {
  display: none;
}

.user-active {
  border-radius: 5px;
  background-color: rgba(40, 131, 26, 1);
  color: #fff;
}

/* .img-box {
  height: 250px;
  width: 90%;
  background-color: #ffff;
} */

.box1 img {
  height: 250px;
  width: 250px;
}
.box2 img {
  height: 550px;
  width: 100%;
}
.box img {
  object-fit: fill;
}
